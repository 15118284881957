<template v-if="hasHelpPage">
    <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon :class="'pb-0' + (noPadding ? ' mt-0' : ' mt-3')" v-on="on" @click="goToHelpPage(helpPageId)">
                <v-icon :class="light ? 'white--text lighten-1' : 'black--text darken-1'">mdi-help-circle-outline</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('message.help') }}</span>
    </v-tooltip>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions} from "vuex";

export default {
    name: "HelpPopUpV2",
    props: ['helpPageComponent','noPadding', 'light'],
    computed: {
        statePrefix() {
            return 'current'
        },
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapFieldsPrefixed('help', {
            TableOfContents: 'TableOfContents'
        },'statePrefix'),
        hasHelpPage () {
            if(this.helpPageComponent != null) {
                let helpPage = this.TableOfContents.find(t => t.TableOfContent.component == this.helpPageComponent)
                if (helpPage) return true
                return false
            }
            return false
        },
        helpPageId () {
            if(this.helpPageComponent != null) {
                let helpPage = this.TableOfContents.find(t => t.TableOfContent.component == this.helpPageComponent)
                return helpPage.TableOfContent.id
            } else {
                return null
            }
        }
    },
    methods: {
        ...mapActions('help',{
            getAdminTableOfContents: 'getAdminTableOfContents',
            getUserTableOfContents: 'getUserTableOfContents'
        }),
        goToHelpPage (helpPageId) {
            let tab = window.open('/v1/help?page=' + helpPageId,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    mounted() {
        if(this.TableOfContents.length == 0){
            if(this.$can('update','HelpPage')){
                this.getAdminTableOfContents()
            } else {
                this.getUserTableOfContents()
            }
        }
    }
}
</script>

<style scoped>

</style>